<template>
  <Page v-if="ownerReport">
    <span slot="title">{{ ownerReport.owner.display_name }}</span>
    <div class="ibox float-e-margin">
      <div class="ibox-title">
        <h5>Eigenaarsrapport</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <div class="col-md-12" style="padding-left: 0px;">
            <div class="col-md-2" style="padding-left: 0px;">
              <div class="widget navy-bg p-lg text-center">
                <div class="m-b-md">
                  <i class="fa fa-4x fa-hand-o-up" ></i>
                  <h1 class="m-xs">{{ ownerReport.stats.site_clicks }}</h1>
                  <h3 class="font-bold no-margins">
                    Kliks op onze website
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-md-2" style="padding-left: 0px;">
              <div class="widget navy-bg p-lg text-center">
                <div class="m-b-md">
                  <i class="fa fa-4x fa-download"></i>
                  <h1 class="m-xs">{{ ownerReport.stats.doc_downloads }}</h1>
                  <h3 class="font-bold no-margins">
                    Document downloads
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-md-2" style="padding-left: 0px;">
              <div class="widget navy-bg p-lg text-center">
                <div class="m-b-md">
                  <i class="fa fa-4x fa-bell"></i>
                  <h1 class="m-xs">{{ ownerReport.stats.hmodh }}</h1>
                  <h3 class="font-bold no-margins">
                    HMODH verstuurd
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" style="padding-left: 0px;" v-if="ownerReport.summary_statistics">
            <h4>Samenvatting statistieken</h4>
            <p>{{ owner_report.summary_statistics }}</p>
          </div>
          <table class="table table-hover" v-if="ownerReport.shortlist">
            <tbody>
            <tr>
              <th colspan="4"><strong>Shortlist</strong></th>
            </tr>
            <template v-for="reportItem in ownerReport.shortlist">
              <tr :key="reportItem.id">
                <td colspan="4">Geïnteresseerde kandidaat:
                  <strong>{{ reportItem.candidate.display_name }}</strong>
                </td>
              </tr>
              <tr v-for="communication in reportItem.communications" :key="communication.id">
                <td style="background-color: #ed5565; color: white; width: 10%;">{{ communication.title }}</td>
                <td style="width: 10%;">{{ communication.date|date-day }}</td>
                <td style="width: 40%;">{{ communication.description }}</td>
                <td style="width: 40%">{{ communication.notes }}</td>
              </tr>
            </template>
            </tbody>
          </table>
          <table class="table table-hover" v-if="ownerReport.breaks">
            <tbody>
            <tr>
              <th colspan="4"><strong>Breaks</strong></th>
            </tr>
            <template v-for="reportItem in ownerReport.breaks">
              <tr :key="reportItem.id">
                <td colspan="4">Geïnteresseerde kandidaat:
                  <strong>{{ reportItem.candidate.display_name }}</strong>
                </td>
              </tr>
              <tr v-for="communication in reportItem.communications" :key="communication.id">
                <td style="background-color: #ed5565; color: white; width: 10%;">{{ communication.title }}</td>
                <td style="width: 10%;">{{ communication.date|date-day }}</td>
                <td style="width: 40%;">{{ communication.description }}</td>
                <td style="width: 40%">{{ communication.notes }}</td>
              </tr>
            </template>
            </tbody>
          </table>
          <table class="table table-hover" v-if="ownerReport.coldlist">
            <tbody>
            <tr>
              <th colspan="4"><strong>Coldlist</strong></th>
            </tr>
            <template v-for="reportItem in ownerReport.coldlist">
              <tr :key="reportItem.id">
                <td colspan="4">Geïnteresseerde kandidaat:
                  <strong>{{ reportItem.candidate.display_name }}</strong>
                </td>
              </tr>
              <tr v-for="communication in reportItem.communications" :key="communication.id">
                <td style="background-color: #ed5565; color: white; width: 10%;">{{ communication.title }}</td>
                <td style="width: 10%;">{{ communication.date|date-day }}</td>
                <td style="width: 40%;">{{ communication.description }}</td>
                <td style="width: 40%">{{ communication.notes }}</td>
              </tr>
            </template>
            </tbody>
          </table>
          <table class="table table-hover" v-if="ownerReport.pubactions">
            <tbody>
            <tr>
              <th colspan="3"><strong>Pub acties</strong></th>
            </tr>
            <template v-for="pubaction in ownerReport.pubactions">
              <tr :key="pubaction.id">
                <td colspan="3"><strong>{{pubaction.category.name_nl}}</strong></td>
              </tr>
              <tr v-for="action in pubaction.actions" :key="action.id">
                <td width="10%">{{ action.type.name_nl }}</td>
                <td width="15%">{{ action.start_date|date-day }}
                  <span v-if="action.end_date">- {{ action.end_date|date-day }}</span>
                </td>
                <td width="75%">{{ action.type.description_nl }}</td>
              </tr>
            </template>

            </tbody>
          </table>
          <div class="col-md-12" style="padding-left: 0px;" v-if="ownerReport.comments">
            <h4>Samenvatting</h4>
            <p>{{ ownerReport.comments }}</p>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/iam/Page'
import { getOwnerReportDetail } from '@/services/ownerReports'
import { errorModal, startLoadingModal, stopLoadingModal } from '@/modalMessages'

export default {
  name: 'OwnerReportDetail',
  components: {
    Page
  },
  data () {
    return {
      ownerReport: null
    }
  },
  created () {
    this.getOwnerReport(this.$route.params.id)
  },
  computed: {
    iconClass (icon) {
      return 'fa-' + icon
    }
  },
  methods: {
    async getOwnerReport (reportId) {
      try {
        startLoadingModal('Details eigenaarsrapport laden...')
        const response = await getOwnerReportDetail(reportId)
        this.ownerReport = response.data
        stopLoadingModal()
        return response
      } catch (error) {
        errorModal('Fout bij het laden van details van het eigenaarsrapport, probeer het opnieuw.')
        console.log(error)
      }
    }
  }
}
</script>
